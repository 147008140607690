import React , { useEffect , useState } from 'react';
// import { Link, useLocation } from "react-router-dom";
import { useLocation } from "react-router-dom";
import menus from "../../pages/menu";
import dataETHChain from "../../assets/fake-data/data-ethChain";
import { merkle_Verify, merkle_Get_Proof } from '../merkletree/Merkletree';

import './header.scss';

import '../button/button.scss';

import { Anchor } from "antd";

import logo from '../../assets/fake-data/logo';
// import ButtonOne from '../button/ButtonOne';
import dataMint from "../../assets/fake-data/data-mint";
import Mint from "../layouts/Mint";
import Web3 from "web3";
import dataEthChain from "../../assets/fake-data/data-ethChain";

const { Link } = Anchor;

const chainData = dataEthChain.mainnet;

const rkapDetails = require("../contracts_new/artifacts/RocKetApeParts.json");
const APECoinDetails = require("../contracts_new/artifacts/ApeCoin.json");

const ethereum = window.ethereum;

const Header = () => {

    const { pathname } = useLocation();

    const [scroll, setScroll] = useState(false);

    const [menuActive, setMenuActive] = useState(null);

    const [ownerAddress, set_Owner_Address] = useState(null);
    const [correctChain, set_Correct_Chain] = useState(false);
    const [isWhitelist, set_Is_Whitelist] = useState(false);
    const [isAPEEnabled,set_Is_APE_Enabled] = useState(false);
    const [isFreeMint, set_Is_Free_Mint] = useState(false);
    const [isClaimed, set_Is_Claimed] = useState(false);
    const [merkleProof, set_Merkle_Proof] = useState([]);
    const [isSaleActive,set_Is_Sale_Active] = useState(false);
    const [isPublicSaleActive,set_Is_Public_Sale_Active] = useState(false);
    const [mintStatus,set_Mint_Status] = useState(0);
    const [approveAPEStatus,set_Approve_APE_Status] = useState(0);
    const [loadingStatus,set_Loading_Status] = useState(1);

    const unitPriceNormalETH = 0.16;
    const unitPriceWLETH = 0.14;
    const [unitPriceNormalAPE, set_Unit_Price_Normal_APE] = useState(30);
    const [unitPriceWLAPE, set_Unit_Price_WL_APE] = useState(25);

    const handleMenuActive = () => {
        setMenuActive(!menuActive);
      };

    const [activeIndex, setActiveIndex] = useState(null);
    const handleDropdown = index => {
        setActiveIndex(index); 
    };

    useEffect(() => {

        // const ethereum = window.ethereum;

        if(!ownerAddress) {
            set_Owner_Address(null);
            set_Is_Claimed(false);
            set_Is_Free_Mint(false);
            set_Is_Whitelist(false);
            set_Is_APE_Enabled(false);
        }

        ethereum && ethereum.on('accountsChanged', async accounts => {
            // console.log("Wallet address is being changed.")
            set_Approve_APE_Status(0);
            set_Loading_Status(1);
            set_Mint_Status(0);
            set_Owner_Address(accounts[0]);
            const _publicSaleIsActive = await check_sale_active("publicSaleIsActive")
            set_Is_Public_Sale_Active(_publicSaleIsActive);
            if (_publicSaleIsActive) {
                switch_address_status("Normal",accounts[0])
            } else {
                const status = await check_preaccess_status(accounts[0]);
                switch_address_status(status, accounts[0])
            }
            const _APECoinApproved = await check_is_apecoin_approved(accounts[0]);
            set_Is_APE_Enabled(_APECoinApproved);
            // sessionStorage.setItem('ownerAddress',accounts[0]);
            set_Loading_Status(0);
        });

        ethereum && ethereum.on('networkChanged', async () => {
            // console.log("Chain is being changed.")
            set_Approve_APE_Status(0);
            set_Loading_Status(1);
            set_Mint_Status(0);
            set_Approve_APE_Status(0)
            if (ethereum.networkVersion !== chainData.networkVersion) {
                set_Correct_Chain(false);
                await ethereum.request({method: 'wallet_switchEthereumChain', params: [{ chainId: chainData.chainId}]});
            } else {
                set_Correct_Chain(true);
            }
            set_Loading_Status(0);
        })

        window.addEventListener("scroll", () => {
            setScroll(window.scrollY > 100);
        });
        return () => {
            setScroll({});
        }
    }, []);

    // web3 functions
    const enable_metamask = async () => {
        if (ethereum) {
            if (ethereum.isMetaMask) {

                ethereum.networkVersion !== chainData.networkVersion && await ethereum.request({method: 'wallet_switchEthereumChain', params: [{ chainId: chainData.chainId}]});

                const accounts = await ethereum.request({method: 'eth_requestAccounts'});
                set_Loading_Status(1);
                set_Owner_Address(accounts[0]);
                set_Correct_Chain(true);

                const _SaleIsActive = await check_sale_active("saleIsActive")
                set_Is_Sale_Active(_SaleIsActive);
                const _publicSaleIsActive = await check_sale_active("publicSaleIsActive")
                set_Is_Public_Sale_Active(_publicSaleIsActive);
                const _APECoinApproved = await check_is_apecoin_approved(accounts[0]);
                set_Is_APE_Enabled(_APECoinApproved);
                const _unitPriceNormalAPE = await check_apecoin_equivalent(unitPriceNormalETH);
                set_Unit_Price_Normal_APE(_unitPriceNormalAPE)
                const _unitPriceWLAPE = await check_apecoin_equivalent(unitPriceWLETH);
                set_Unit_Price_WL_APE(_unitPriceWLAPE)

                console.log("Sales is active :", _SaleIsActive);
                console.log("Public Sales is active :", _publicSaleIsActive);
                console.log("Connected wallet :", accounts[0]);

                if(_publicSaleIsActive) {
                    switch_address_status("Normal", accounts[0])
                } else {
                    const status = await check_preaccess_status(accounts[0]);
                    switch_address_status(status,accounts[0])
                }

                set_Loading_Status(0);
            }
        }
    }
    const mint_rocketape_eth = async (unit, totalPrice, merkleProof) => {
        set_Mint_Status(1)
        const web3 = new Web3(ethereum)
        const contract = new web3.eth.Contract(rkapDetails.abi,chainData.rkapAddress)
        await contract.methods.mint_by_eth(unit,merkleProof).send({
            from:ownerAddress,
            value: web3.utils.toWei(totalPrice,"ether")
        })
            .on("receipt", receipt => {
                set_Mint_Status(2);
                if(!isPublicSaleActive){
                    set_Is_Claimed(true);
                }})
            .on("confirmation", (confirmationNumber, receipt) => console.log(receipt))
            .on("error", (error, receipt) => set_Mint_Status(0));
    }
    const mint_rocketape_ape = async (unit, totalPrice, merkleProof) => {
        set_Mint_Status(1)
        const web3 = new Web3(ethereum)
        const contract = new web3.eth.Contract(rkapDetails.abi,chainData.rkapAddress)
        await contract.methods.mint_by_ape(unit,merkleProof).send({
            from:ownerAddress
        })
            .on("receipt", receipt => {
                set_Mint_Status(2)
                if(!isPublicSaleActive){
                    set_Is_Claimed(true);
                }})
            .on("confirmation", (confirmationNumber, receipt) => console.log(receipt))
            .on("error", (error, receipt) => set_Mint_Status(0));
    }
    const check_is_apecoin_approved = async (address) => {

        const web3 = new Web3(ethereum);
        const contract = new web3.eth.Contract(APECoinDetails.abi,chainData.APECoinAddress);

        try {
            const apeAllowance = await contract.methods.allowance(address,chainData.rkapAddress).call();
            if(apeAllowance < web3.utils.toWei("10000","ether")) {
                return false
            } else {
                return true
            }
        } catch (err) {
            return false
        }

    }
    const check_apecoin_equivalent = async (unit_price_eth) => {
        const web3 = new Web3(ethereum);
        const contract = new web3.eth.Contract(rkapDetails.abi,chainData.rkapAddress)
        const _tempPrice = await contract.methods.getRocketSetPriceAPE(web3.utils.toWei(unit_price_eth.toString(),"ether")).call();
        // console.log(web3.utils.fromWei(_tempPrice,"ether"))

        return Number(web3.utils.fromWei(_tempPrice,"ether"));
    }
    const approve_apecoin = async (address) => {
        set_Approve_APE_Status(1)
        const web3 = new Web3(ethereum)
        const contract = new web3.eth.Contract(APECoinDetails.abi,chainData.APECoinAddress)
        const totalSupply = await contract.methods.totalSupply().call();
        await contract.methods.approve(chainData.rkapAddress,totalSupply).send({
            from:address,
        })
            .on("receipt", receipt => {
                set_Is_APE_Enabled(true)
                set_Approve_APE_Status(2)})
            .on("confirmation", (confirmationNumber, receipt) => console.log(receipt))
            .on("error", (error, receipt) => set_Approve_APE_Status(0));
    }
    const check_sale_active = async (check_type) => {
        const web3 = new Web3(ethereum)
        const contract = new web3.eth.Contract(rkapDetails.abi,chainData.rkapAddress)
        let ans;
        switch (check_type) {
            case "saleIsActive":
                ans = await contract.methods.saleIsActive().call();
                break;
            case "publicSaleIsActive":
                ans = await contract.methods.publicSaleIsActive().call();
                break;
            default:
                ans = false;
        }
        // console.log(check_type, ":", ans)
        return ans
    }
    const check_is_claimed = async (address) => {
        const web3 = new Web3(ethereum)
        const contract = new web3.eth.Contract(rkapDetails.abi,chainData.rkapAddress)
        let ans;
        try {
            ans = await contract.methods.whitelistClaimed(address).call();
        } catch (err) {
            ans = false
        }

        return ans
    }
    const check_preaccess_status = async (address) => {
        const _isClaimed = await check_is_claimed(address);
        const _isFM = merkle_Verify(address,"fm");
        const _isWL = merkle_Verify(address,"wl");

        if (_isClaimed && (_isFM || _isWL)) {
            return "isClaimed"
        } else if (_isFM) {
            return "isFreeMint"
        } else if (_isWL) {
            return "isWhiteList"
        } else {
            return "Normal"
        }
    }
    const switch_address_status = (status,address) => {
        console.log("Switched to :", status);
        switch (status) {
            case "isClaimed":
                set_Is_Claimed(true);
                set_Is_Free_Mint(false);
                set_Is_Whitelist(false);
                set_Merkle_Proof([]);
                break;
            case "isFreeMint":
                set_Is_Claimed(false);
                set_Is_Free_Mint(true);
                set_Is_Whitelist(false);
                set_Merkle_Proof(merkle_Get_Proof(address,"fm"));
                break;
            case "isWhiteList":
                set_Is_Claimed(false);
                set_Is_Free_Mint(false);
                set_Is_Whitelist(true);
                set_Merkle_Proof(merkle_Get_Proof(address,"wl"));
                break;
            default:
                set_Is_Claimed(false)
                set_Is_Free_Mint(false);
                set_Is_Whitelist(false);
                set_Merkle_Proof([]);

            // console.log("Is Claimed :", isClaimed);
            // console.log("Free Mint :", isFreeMint);
            // console.log("White Listed :", isWhitelist);

        }
    }

    return (
        <div>
            <header id="header_main" className={`header js-header ${scroll ? 'is-fixed' : ''}`}>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <Anchor affix={false} targetOffset={20}>
                                <div className="header__body d-flex justify-content-between">
                                    <div className="header__logo">
                                        <a href='/'>
                                            <img id="site-logo" src={logo} alt="RocKetApe"/>
                                        </a>
                                    </div>

                                    <div className="header__right">
                                        <nav id="main-nav" className={`main-nav ${menuActive ? 'active' : ''}`}>
                                            <ul id="menu-primary-menu" className="menu">
                                                {
                                                    menus.map((data,index) => (
                                                        <li key={index} onClick={()=> handleDropdown(index)} className={`menu-item ${data.namesub ? 'menu-item-has-children' : '' } ${activeIndex === index ? 'active' : ''} ${pathname === data.links ? 'active' : ''} ` }   >
                                                            {/*<a href={data.links}>{data.name}</a>*/}
                                                            {/*<Link href= {data.links} activeClassName="active" title = {data.name} />*/}
                                                            <a href={data.links}>
                                                                {data.name}
                                                            </a>
                                                            {
                                                                data.namesub &&
                                                                <ul className="sub-menu" >
                                                                    {
                                                                        data.namesub.map((submenu) => (
                                                                            <li key={submenu.id} className={
                                                                                pathname === submenu.links
                                                                                    ? "menu-item current-item"
                                                                                    : "menu-item"
                                                                            }><Link to={submenu.links}>{submenu.sub}</Link>
                                                                            </li>
                                                                        ))
                                                                    }
                                                                </ul>
                                                            }

                                                        </li>
                                                    ))
                                                }
                                                {
                                                    !ownerAddress ?
                                                        <div className="button">
                                                            <a onClick={() => enable_metamask()} className="btn-action">
                                                                Connect
                                                            {/*<a href='https://www.twitter.com/ape_ket' target="_blank" className="btn-action">*/}
                                                            {/*    Mint on 22 Dec!*/}
                                                            </a>
                                                        </div> :
                                                    !correctChain ?
                                                        <div className="button">
                                                            <a className="btn-disable">
                                                                Wrong Network
                                                            </a>
                                                        </div> :
                                                        <div className="button">
                                                            <a href="/#mint" className="btn-action">
                                                                Mint
                                                            </a>
                                                        </div>
                                                    // <Link href= "/#mint" activeClassName="active"  title = "Mint" />
                                                }
                                            </ul>
                                        </nav>
                                        {/*{*/}
                                        {/*    !ownerAddress ?*/}
                                        {/*        <div className="button">*/}
                                        {/*            <a onClick={() => enable_metamask()} className="btn-action">*/}
                                        {/*                Connect*/}
                                        {/*            </a>*/}
                                        {/*        </div> :*/}
                                        {/*    !correctChain ?*/}
                                        {/*        <div className="button">*/}
                                        {/*            <a className="btn-disable">*/}
                                        {/*                Wrong Network*/}
                                        {/*            </a>*/}
                                        {/*        </div> :*/}
                                        {/*        <div className="button">*/}
                                        {/*            <a href="/#mint" className="btn-action">*/}
                                        {/*                Mint*/}
                                        {/*            </a>*/}
                                        {/*         </div>*/}
                                        {/*        // <Link href= "/#mint" activeClassName="active"  title = "Mint" />*/}
                                        {/*}*/}
                                        <div className={`mobile-button ${menuActive ? 'active' : ''}`} onClick={handleMenuActive}><span></span></div>
                                    </div>
                                </div>
                            </Anchor>
                        </div>
                    </div>
                    <div className="row notification-bar-diy">
                        <span className="marqueeStyle">Attention : For every <a style={{color:"orangered"}}>100</a> mints, we will have a <a style={{color:"orangered"}}>1 ETH</a> raffle until mint out!</span>
                    </div>
                </div>
            </header>
            {
                ownerAddress && correctChain ?
                    <Mint data = {dataMint}
                          isWhitelist = {isWhitelist}
                          ownerAddress = {ownerAddress}
                          isFreeMint = {isFreeMint}
                          isClaimed = {isClaimed}
                          isSaleActive = {isSaleActive}
                          isPublicSaleActive = {isPublicSaleActive}
                          isAPEEnabled = {isAPEEnabled}
                          merkleProof = {merkleProof}
                          mintStatus = {mintStatus}
                          approveAPEStatus = {approveAPEStatus}
                          loadingStatus={loadingStatus}
                          unitPriceNormalETH = {unitPriceNormalETH}
                          unitPriceWLETH = {unitPriceWLETH}
                          unitPriceNormalAPE = {unitPriceNormalAPE}
                          unitPriceWLAPE = {unitPriceWLAPE}
                          set_Approve_APE_Status={set_Approve_APE_Status}
                          mint_rocketape_eth = {mint_rocketape_eth}
                          mint_rocketape_ape = {mint_rocketape_ape}
                          check_is_apecoin_approved = {check_is_apecoin_approved}
                          check_apecoin_equivalent = {check_apecoin_equivalent}
                          approve_apecoin = {approve_apecoin} /> :
                    <></>
            }
        </div>
    );
}

export default Header;
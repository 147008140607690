import mint1 from '../images/mint/mint-img1.png'
import mint2 from '../images/mint/mint-img2.png'
import ape from '../images/mint/apes.png'
import eth from '../images/mint/eths.png'

const dataMint = {
        img1: mint1,
        img2: mint2,
        ape: ape,
        eth: eth,
    }

export default dataMint;
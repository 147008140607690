import consultant from '../images/team/consultant.png'
import engineer from '../images/team/engineer.jpg'
import architect from '../images/team/architect.jpg'

const dataTeam = [
    {
        img: consultant,
        name:'NEGI',
        position: 'The Consultant',
        twitter: 'https://www.twitter.com/negithenagi'
    },
    {
        img: engineer,
        name:'Feymann',
        position: 'The Engineer',
        twitter: 'https://www.twitter.com/wkky_haha'
    },
    {
        img: architect,
        name:'TL',
        position: 'The Architect',
        twitter: 'https://www.twitter.com/ThonasHK'
    },
]

export default dataTeam;
const dataRoadMap = [
    {
        time: 'Phase 1',
        title: 'Idea Generation',
        desc:'We created our concept of RocKetApe together with the Framework and also the NFT arts and Smart Contracts.',
        desc1:'- Concept Creation',
        desc2:'- Frame Development',
        desc3:'- NFT Creation',
        class: 'left'
    },
    {
        time: 'Phase 2',
        title: 'Mint & Assembly',
        desc: 'We will have our mint completion, then we will have the rocket assembly engine for your usage. We will also inject 5% royalty reserve for further development.',
        desc1: '- Mint Complete',
        desc2: '- Rocket Assembly',
        desc3: '- 5% Royalty reserve for development',
        class: 'right mt-223'
    },
    {
        time: 'Phase 3',
        title: 'Reward & Development',
        desc: 'RocKetApe Owners can enjoy assemble to earn from RKA Token and receive your own 3D RocKet! We will also purchase Lands for our RocKetLand.',
        desc1: 'Assemble to earn\nLand purchase for RocKetLand\n3D RocKet sent to all owners',
        desc2: 'Assemble to earn\nLand purchase for RocKetLand\n3D RocKet sent to all owners',
        desc3: 'Assemble to earn\nLand purchase for RocKetLand\n3D RocKet sent to all owners',
        class: 'left mt--23'
    },
    {
        time: 'Phase 4',
        title: 'To the VERSE',
        desc: 'We will build up our Play to Earn metaverse for the RocKetApe Community. Also we will pick the one and send your item to the Universe! And more is coming...',
        desc1: 'Play to earn for RocKetApe Community \nSend your item to the Universe\nAns more...',
        desc2: 'Play to earn for RocKetApe Community \nSend your item to the Universe\nAns more...',
        desc3: 'Play to earn for RocKetApe Community \nSend your item to the Universe\nAns more...',
        class: 'right mt-200'
    },
]

export default dataRoadMap;

// const dataRoadMap = [
//     {
//         time: 'Phase 1',
//         title: 'Idea Generation',
//         desc1: 'Concept Creation',
//         desc2: 'Frame Development',
//         desc3: 'NFT Creation',
//         class: 'left'
//     },
//     {
//         time: 'Phase 2',
//         title: 'Mint & Assembly',
//         desc1: 'Mint Complete',
//         desc2: 'Rocket Assembly',
//         desc3: '5% Royalty reserve for development',
//         class: 'right mt-223'
//     },
//     {
//         time: 'Phase 3',
//         title: 'Reward & Development',
//         desc1: 'Assemble to earn',
//         desc2: 'Land purchase for RocKetLand',
//         desc3: '3D RocKet sent to all owners',
//         class: 'left mt--23'
//     },
//     {
//         time: 'Phase 4',
//         title: 'To the VERSE',
//         desc1: 'Play to earn for RocKetApe Community',
//         desc2: 'Send your item to the Universe',
//         desc3: 'Ans more...',
//         class: 'right mt-200'
//     },
// ]

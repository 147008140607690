import React, { useState } from 'react';
import { Link } from 'react-router-dom'
import ReactPlayer from "react-player";
import item from '../../assets/fake-data/item';

const About = () => {

    const [data] = useState(
        {
            subtitle: 'About Us',
            title: 'Say Hi to 39 BAYCs in the space!',
            desc1: '39 BAYCs are bored so they start exploring the world with their companions.',
            desc2: 'We build own Rockets and travel the universe!',
        }
    )

    return (
        <section id="aboutus" className="tf-section section-about">
            <div className="container">
                <div className="row reverse">
                    <div className="col-xl-7 col-md-12 center-element">
                        <ReactPlayer
                            url="https://www.youtube.com/watch?v=Nl1t6VD7FVQ"
                            controls={true}
                            loop={true}
                            muted={true}
                            playing={true}
                            volume={0}
                            width="100%"
                        />


                        {/*<div className="group-image">*/}
                            {/*<div className="left">*/}
                            {/*    /!*<div className="item bg-1"><img src={item.item8} alt="RocKetApe" /></div>*!/*/}
                            {/*</div>*/}
                            {/*<div className="right">*/}
                            {/*    /!*<div className="item bg-2"><img src={item.item9} alt="RocKetApe" /></div>*!/*/}
                            {/*    /!*<div className="item bg-3"><img src={item.item10} alt="RocKetApe" /></div>*!/*/}
                            {/*</div>*/}
                        {/*</div>*/}

                    </div>
                    <div className="col-xl-5 col-md-12">
                        <div className="block-text pt-12">
                            <h5 className="sub-title mb-10" data-aos="fade-up" data-aos-duration="1000">{data.subtitle}</h5>
                            <h3 className="title mb-58" data-aos="fade-up" data-aos-duration="1000">{data.title}</h3>
                            <p className="fs-21 mb-33" data-aos="fade-up" data-aos-duration="1000">{data.desc1}</p>
                            <p className="fs-18 line-h17 mb-41" data-aos="fade-up" data-aos-duration="1000">{data.desc2}</p>
                            {/*<Link to="/about" className="btn-action style-2"  data-aos="fade-up" data-aos-duration="1200">More About Us</Link>*/}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default About;
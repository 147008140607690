import img2 from '../images/mp/mp2.png'
import img3 from '../images/mp/mp3.png'
import img4 from '../images/mp/mp4.png'
import img5 from '../images/mp/mp5.png'
import img6 from '../images/mp/mp6.png'
import img7 from '../images/mp/mp7.png'

const dataPartners = [
    {
        img: img2,
        border: 'border-r border-b',
        style: {width:"100%",objectFit:"contain"}
    },
    {
        img: img3,
        border: 'border-r border-b',
        style: {width:"80%",objectFit:"contain"}
    },
    {
        img: img4,
        border: 'border-b',
        style: {width:"50%",objectFit:"contain"}
    },
    {
        img: img5,
        border: 'border-r',
        style: {width:"80%",objectFit:"contain"}
    },
    {
        img: img6,
        border: 'border-r',
        style: {width:"80%",objectFit:"contain"}
    },
    {
        img: img7,
        border: 'border-r',
        style: {width:"80%",objectFit:"contain"}
    }
]

export default dataPartners;
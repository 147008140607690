const menus = [
    {
        id: 1,
        name: 'Apestronaut',
        links: '/#apestronaut',
        // namesub: [
        //     {
        //         id: 1,
        //         sub: 'Home 01',
        //         links: '/'
        //     },
        //     {
        //         id: 2,
        //         sub: 'Home 02',
        //         links: '/home-02'
        //     },
        //     {
        //         id: 3,
        //         sub: 'Home 03',
        //         links: '/home-03'
        //     },
        //     {
        //         id: 4,
        //         sub: 'Home One Page',
        //         links: '/home-one-page/'
        //     },
        // ]
    },
    {
        id: 2,
        name: 'About Us',
        links: '/#aboutus'
    },
    {
        id: 3,
        name: 'Features',
        links: '/#features'
    },
    {
        id: 4,
        name: 'Road Map',
        links: '/#roadmap',
        // namesub: [
        //     {
        //         id: 1,
        //         sub: 'Team',
        //         links: '/team'
        //     },
        //     {
        //         id: 2,
        //         sub: 'Road Map',
        //         links: '/road-map'
        //     },
        //     {
        //         id: 3,
        //         sub: 'Our Mission',
        //         links: '/our-mission'
        //     },
        //     {
        //         id: 4,
        //         sub: 'Blog List',
        //         links: '/blog-list'
        //     },
        //     {
        //         id: 5,
        //         sub: 'Blog Grid',
        //         links: '/blog-grid'
        //     },
        //     {
        //         id: 6,
        //         sub: 'Blog Single',
        //         links: '/blog-single'
        //     },
        //     {
        //         id: 7,
        //         sub: 'FAQ',
        //         links: '/faq'
        //     },
        // ],
    },
    {
        id: 5,
        name: 'Pioneer',
        links: '/#pioneer'
    },
    // {
    //     id: 6,
    //     name: 'Partners',
    //     links: '/#partners'
    // },
    
]

export default menus;
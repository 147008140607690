const item = {
    item1 : require('../images/rocket3/112.png'),
    item2 : require('../images/rocket3/175.png'),
    item3 : require('../images/rocket3/508.png'),
    item4 : require('../images/rocket3/817.png'),
    item5 : require('../images/rocket3/836.png'),
    item6 : require('../images/rocket3/1130.png'),
    item7 : require('../images/rocket3/1414.png'),
    item8 : require('../images/rocket3/1681.png'),
    item9 : require('../images/rocket3/1939.png'),
    item10 : require('../images/rocket3/1990.png'),
    item11 : require('../images/rocket3/2126.png'),
    item12 : require('../images/rocket3/2174.png'),
    item13 : require('../images/rocket3/2247.png'),
    item14 : require('../images/rocket3/2607.png'),
    item15 : require('../images/rocket3/2851.png'),
    item16 : require('../images/rocket3/2893.png'),
    item27 : require('../images/rocket3/2956.png'),
    item28 : require('../images/rocket3/3015.png'),
    item29 : require('../images/rocket3/3219.png'),
    technology : require('../images/items/technology.png'),
}

export default item;
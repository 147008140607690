import React , { useState ,useEffect } from 'react';
import { Link } from 'react-router-dom';
import './footer.scss';

import logo from '../../assets/fake-data/logo';
import twitter_svg from "../../svg/twitter.svg";
import discord_svg from "../../svg/discord.svg";
import os_svg from "../../svg/opensea.svg";
import looksrare_svg from "../../svg/looksrare.svg";

const Footer = () => {

    const [isVisible, setIsVisible] = useState(false);

    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    };
  
    useEffect(() => {
      const toggleVisibility = () => {
        if (window.pageYOffset > 500) {
          setIsVisible(true);
        } else {
          setIsVisible(false);
        }
      };
  
      window.addEventListener("scroll", toggleVisibility);
  
      return () => window.removeEventListener("scroll", toggleVisibility);
    }, []);

    return (
        <>
        <footer className="footer">
            <div className="container" style={{paddingTop:"50px"}}>
                <div className="row">
                    <div className="footer__body">
                      {/*<Link to="/">*/}
                      {/*  <img src={logo} alt="RocKetApe" data-aos="fade-down" />*/}
                      {/*</Link>*/}
                        
                        {/*<p className="desc fs-18" data-aos="fade-up">*/}
                        {/*    Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occae cat cupidatat non proident, sunt in culpa qui officia dese runt mollit ani */}
                        {/*</p>*/}
                        <div className="block-text center">
                            <h5 className="sub-title mb-10" data-aos="fade-up" data-aos-duration="1000">Follow Us</h5>
                        </div>
                        <ul className="social">
                            {/*<li data-aos="fade-up" data-aos-duration="1000"><Link to="#"><i className="fab fa-facebook-f"></i></Link></li>*/}
                            {/*<li data-aos="fade-up" data-aos-duration="1200"><a href="https://www.twitter.com/ape_ket" target="_blank"><i className="fab fa-twitter"></i></a></li>*/}
                            {/*<li data-aos="fade-up" data-aos-duration="1400"><a href="https://discord.com/invite/TxDtpNnEEZ" target="_blank"><i className="fab fa-discord"></i></a></li>*/}
                            {/*<li data-aos="fade-up" data-aos-duration="1600"><Link to="#"><i className="fab fa-youtube"></i></Link></li>*/}
                            <a className="social-media-logo" href="https://www.twitter.com/ape_ket" target="_blank" >
                                <img src={twitter_svg}
                                     height={60}
                                     width={60} />
                            </a>
                            <a className="social-media-logo" href="https://discord.gg/fn66mFpbnh" target="_blank" style={{padding:"15px"}}>
                                <img src={discord_svg}
                                     height={60}
                                     width={60} />
                            </a>
                            <a className="social-media-logo" href="https://opensea.io/collection/rkac" target="_blank" style={{padding:"15px"}}>
                                <img src={os_svg}
                                     height={60}
                                     width={60}/>
                            </a>
                            <a className="social-media-logo" href="https://looksrare.org/collections/0x73786a35eC4c602C93a6d69f4df2C261BDd57439" target="_blank" style={{padding:"15px"}}>
                                <img src={looksrare_svg}
                                     height={60}
                                     width={60}/>
                            </a>
                        </ul>
                    </div>
                    <div className="footer_bottom">
                        <p className="fs-16">RocKetApe is not affiliated with Yuga Labs </p>
                        <p className="fs-16">Copyright © 2022, RocKetApe - All rights reserved </p>
                        {/*<ul>*/}
                        {/*    <li><Link to="#">Terms & Condition</Link></li>*/}
                        {/*    <li><Link to="#">Privacy Policy</Link></li>*/}
                        {/*    <li><Link to="#">Cookie Policy</Link></li>*/}
                        {/*</ul>*/}
                    </div>
                </div>
            </div>
        </footer>
        {
            isVisible &&
            <div className="ease-in-transition">
                <Link  onClick={scrollToTop}  to='#' id="scroll-top"></Link>
            </div>
        }</>
        
    );
}

export default Footer;
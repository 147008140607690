const dataETHChain = {

    mainnet: {
        chainId : "0x1",
        networkVersion : "1",
        rkapAddress : "0x73786a35eC4c602C93a6d69f4df2C261BDd57439",
        APECoinAddress : "0x4d224452801ACEd8B2F0aebE155379bb5D594381"
    },
    rinkeby: {
        chainId : "0x4",
        networkVersion : "4",
        rkapAddress : "0x3d92D2bb759b9998D48fB219d40D7024b1D7ab7a",
        APECoinAddress : "0x1e1EDced17d49d0130Cdc98489E94194771D81B4"
    }
}

export default dataETHChain;
import React , { useState } from 'react';
import { Link } from 'react-router-dom'
import { Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import item from '../../assets/fake-data/item';
import rocketapes from "../../assets/fake-data/rocketapes";
import twitter_svg from '../../svg/twitter.svg';
import discord_svg from '../../svg/discord.svg';
import os_svg from '../../svg/opensea.svg';
import looksrare_svg from '../../svg/looksrare.svg';

const random_images = (images,number_of_images_to_show) =>{
    const numbers = []; // new empty array
    const ran_images = [];
    let p;
    for (let i = 0; i < number_of_images_to_show; i++) {
        do {
            const n = Math.floor(Math.random() * (images.length));
            p = numbers.includes(n);
            if(!p){
                numbers.push(n);
                ran_images.push(images[n])
            }
        }
        while(p);
    }

    return ran_images
}

const SliderOne = () => {

    const [datatext] = useState({
        subtitle: 'Now is the time to',
        title: 'Fly to the Universe and the Metaverse',
        desc: 'Assemble your RocKetApe, and win your ride to the Universe.'
    })

    return (
        <section id='apestronaut' className="tf-section hero-slider">
            <div className="container">
                <div className="row">
                    <div className="col-xl-5 col-md-6 col-12">
                        <div className="block-text pt-24" >
                            <h6 className="sub-title mb-6" data-aos="fade-up" style={{fontSize:"30px"}}>{datatext.subtitle}</h6>
                            <h2 className="title mb-26" data-aos="fade-up" >{datatext.title}</h2>
                            <p className="title mb-43" data-aos="fade-up" >{datatext.desc}</p>
                            <a className="social-media-logo" href="https://www.twitter.com/ape_ket" target="_blank" >
                                <img src={twitter_svg}
                                       height={60}
                                       width={60} />
                            </a>
                            <a className="social-media-logo" href="https://discord.gg/fn66mFpbnh" target="_blank" style={{padding:"15px"}}>
                                <img src={discord_svg}
                                       height={60}
                                       width={60} />
                            </a>
                            <a className="social-media-logo" href="https://opensea.io/collection/rkac" target="_blank" style={{padding:"15px"}}>
                                <img src={os_svg}
                                       height={60}
                                       width={60}/>
                            </a>
                            <a className="social-media-logo" href="https://looksrare.org/collections/0x73786a35eC4c602C93a6d69f4df2C261BDd57439" target="_blank" style={{padding:"15px"}}>
                                <img src={looksrare_svg}
                                     height={60}
                                     width={60}/>
                            </a>
                            {/*<Link href="https://looksrare.org/collections/0x73786a35eC4c602C93a6d69f4df2C261BDd57439">*/}
                            {/*<a className="social-media-logo-disable" style={{padding:"15px"}}>*/}
                            {/*    <img src={looksrare_svg}*/}
                            {/*           height={60}*/}
                            {/*           width={60}/>*/}
                            {/*</a>*/}
                        </div>
                    </div>
                    <div className="col-xl-7 col-md-6 col-12">
                        <div className="content-right d-flex">
                            <Swiper
                                modules={[ Autoplay ]}
                                direction={"vertical"}
                                spaceBetween={30}
                                slidesPerView={3}
                                loop
                                autoplay={{
                                    delay: 1,
                                    disableOnInteraction: false,
                                    pauseOnMouseEnter: true,
                                }}
                                speed= {2000}
                            >
                                {
                                    random_images(rocketapes,9).map((data,index) => (
                                        <SwiperSlide><div className="item bg-1"><img src={data.path} style={{width:"170px"}} alt={data.idx} /></div></SwiperSlide>
                                    ))
                                }

                            </Swiper>
                            <Swiper
                                modules={[ Autoplay ]}
                                direction={"vertical"}
                                spaceBetween={30}
                                slidesPerView={3}
                                loop
                                autoplay={{
                                    delay: 1,
                                    reverseDirection: true,
                                    disableOnInteraction: false,
                                    pauseOnMouseEnter: true,
                                }}
                                speed= {2000}
                            >
                                {
                                    random_images(rocketapes,9).map((data,index) => (
                                        <SwiperSlide><div className="item bg-1"><img src={data.path} style={{width:"170px"}} alt={data.idx} /></div></SwiperSlide>
                                    ))
                                }

                            </Swiper>
                            <Swiper
                                modules={[ Autoplay ]}
                                direction={"vertical"}
                                spaceBetween={30}
                                slidesPerView={3}
                                loop
                                autoplay={{
                                    delay: 1,
                                    disableOnInteraction: false,
                                    pauseOnMouseEnter: true,
                                }}
                                speed= {2000}
                            >
                                {
                                    random_images(rocketapes,9).map((data,index) => (
                                        <SwiperSlide><div className="item bg-1"><img src={data.path} style={{width:"170px"}} alt={data.idx} /></div></SwiperSlide>
                                    ))
                                }

                            </Swiper>
                        </div>
                        <h2 style = {{color:"white",backgroundColor:'transparent',textAlign: "right",paddingTop: "35px",fontSize: "25px"}}>These IPs were provided by owner as at 17 Nov 2021.</h2>
                    </div>
                </div>
            </div>
        </section>
    );

}

export default SliderOne;
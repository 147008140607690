import img1 from '../images/icon/hosting.png'
import img2 from '../images/icon/provision.png'
import img3 from '../images/icon/badge.png'
import img4 from '../images/icon/chat.png'

const dataCard = [
    {
        stt: '01',
        title:'Lucky Bag',
        desc: 'You get 4 or 5 rocket components when you mint our RocKetApe. Each component can be traded separately on the market. ',
        class: 'mr-28',

        class2: '',
        img: img1
    },
    {
        stt: '02',
        title:'Assembly of Components',
        desc: 'There are 4 core components for each RocKetApe. Gather all components you want and build your dream RocKetApe! Do bear in mind the assembly is NON-REVERSIBLE.',
        class: 'mt-45',

        class2: 's2',
        img: img2
    },
    {
        stt: '03',
        title:'Claim ETH',
        desc: 'You receive 1 RKA token when assembling the components into a RocKetApe. There will be 3 tiers of token and it will be used to claim the reserved pool for ETH after 100% mint.',
        class: 'mr-28 mt--16',

        class2: 's2-m',
        img: img3
    },
    {
        stt: '04',
        title:'RocKet Attributes',
        desc: 'You get status bars for your RocKetApe with ATK, HP, ACC and SPD. These numbers are inherited from your rocket parts. Thrilled to see these in the Metaverse?',
        class: 'mt-29',

        class2: 's2',
        img: img4
    },
]

export default dataCard;
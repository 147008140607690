const freeMintAddresses = require("./freeMintAddresses.json");
const whitelistAddresses = require("./whitelistAddresses.json");
const { MerkleTree } = require('merkletreejs');
const keccak256 = require('keccak256');

const whitelistAddressesEnc = whitelistAddresses.addresses.map(address => keccak256(address));
const merkleTreeWL = new MerkleTree(whitelistAddressesEnc, keccak256, {sortPairs: true});
const rootHashWL = merkleTreeWL.getRoot();
console.log("rootHashWL :",'0x'+ rootHashWL.toString('hex'))

const freeMintAddressesEnc = freeMintAddresses.addresses.map(address => keccak256(address));
const merkleTreeFM = new MerkleTree(freeMintAddressesEnc, keccak256, {sortPairs: true});
const rootHashFM = merkleTreeFM.getRoot();
console.log("rootHashFM :",'0x'+ rootHashFM.toString('hex'))

const merkle_Verify =  (address, address_type) => {

    const address_k256 = keccak256(address);

    return  address_type === "fm" ?
                merkleTreeFM.verify(merkleTreeFM.getHexProof(address_k256), address_k256, rootHashFM) :
            address_type === "wl" ?
                merkleTreeWL.verify(merkleTreeWL.getHexProof(address_k256), address_k256, rootHashWL) :
                false
}

const merkle_Get_Proof =  (address, address_type) => {

    return  address_type === "fm" ?
                merkleTreeFM.getHexProof(keccak256(address)) :
            address_type === "wl" ?
                merkleTreeWL.getHexProof(keccak256(address)):
                ["0xc1f172275fad1c014e2640df5b835c4e92f3ff1fa6e448d857fce316b9fd21e8"]
}

export { merkle_Verify, merkle_Get_Proof };


const rocketapes = [
    {'idx': '836', 'path': require('../images/rocket3/836.png')},
    {'idx': '5478', 'path': require('../images/rocket3/5478.png')},
    {'idx': '175', 'path': require('../images/rocket3/175.png')},
    {'idx': '5889', 'path': require('../images/rocket3/5889.png')},
    {'idx': '9480', 'path': require('../images/rocket3/9480.png')},
    {'idx': '3219', 'path': require('../images/rocket3/3219.png')},
    {'idx': '2893', 'path': require('../images/rocket3/2893.png')},
    {'idx': '2851', 'path': require('../images/rocket3/2851.png')},
    {'idx': '2956', 'path': require('../images/rocket3/2956.png')},
    {'idx': '2174', 'path': require('../images/rocket3/2174.png')},
    {'idx': '3653', 'path': require('../images/rocket3/3653.png')},
    {'idx': '1939', 'path': require('../images/rocket3/1939.png')},
    {'idx': '3486', 'path': require('../images/rocket3/3486.png')},
    {'idx': '2607', 'path': require('../images/rocket3/2607.png')},
    {'idx': '9034', 'path': require('../images/rocket3/9034.png')},
    {'idx': '1130', 'path': require('../images/rocket3/1130.png')},
    {'idx': '1681', 'path': require('../images/rocket3/1681.png')},
    {'idx': '7196', 'path': require('../images/rocket3/7196.png')},
    {'idx': '9178', 'path': require('../images/rocket3/9178.png')},
    {'idx': '112', 'path': require('../images/rocket3/112.png')},
    {'idx': '8847', 'path': require('../images/rocket3/8847.png')},
    {'idx': '4452', 'path': require('../images/rocket3/4452.png')},
    {'idx': '5570', 'path': require('../images/rocket3/5570.png')},
    {'idx': '9574', 'path': require('../images/rocket3/9574.png')},
    {'idx': '7201', 'path': require('../images/rocket3/7201.png')},
    {'idx': '508', 'path': require('../images/rocket3/508.png')},
    {'idx': '5417', 'path': require('../images/rocket3/5417.png')},
    {'idx': '3015', 'path': require('../images/rocket3/3015.png')},
    {'idx': '1414', 'path': require('../images/rocket3/1414.png')},
    {'idx': '6352', 'path': require('../images/rocket3/6352.png')},
    {'idx': '817', 'path': require('../images/rocket3/817.png')},
    {'idx': '9077', 'path': require('../images/rocket3/9077.png')},
    {'idx': '5507', 'path': require('../images/rocket3/5507.png')},
    {'idx': '1990', 'path': require('../images/rocket3/1990.png')},
    {'idx': '3403', 'path': require('../images/rocket3/3403.png')},
    {'idx': '6779', 'path': require('../images/rocket3/6779.png')},
    {'idx': '8231', 'path': require('../images/rocket3/8231.png')},
    {'idx': '2126', 'path': require('../images/rocket3/2126.png')},
    {'idx': '2247', 'path': require('../images/rocket3/2247.png')}
]

export default rocketapes;
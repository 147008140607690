import React , { useState, useEffect } from 'react';

const maximum_per_mint = 2;

const Mint = (props) => {

    const [unit_price_eth, set_unit_price_eth] = useState(props.unitPriceNormalETH);
    const [unit_price_ape, set_unit_price_ape] = useState(props.unitPriceNormalAPE);
    const [max_mint, set_max_mint] = useState(maximum_per_mint);
    const [unit,setUnit] = useState(1);
    const [byETH, setByETH] = useState(true);
    const [status,setStatus] = useState('');
    const total_price_eth = (unit * unit_price_eth).toFixed(2);
    const total_price_ape = (unit * unit_price_ape);
    const unit_is_int = Number.isInteger(unit);

    useEffect(() => {
        if (props.isFreeMint) {
            set_unit_price_eth(0);
            set_unit_price_ape(0);
            set_max_mint(maximum_per_mint-1)
        } else if (props.isWhitelist) {
            set_unit_price_eth(props.unitPriceWLETH);
            set_unit_price_ape(props.unitPriceWLAPE);
            set_max_mint(maximum_per_mint)
        } else {
            set_unit_price_eth(props.unitPriceNormalETH);
            set_unit_price_ape(props.unitPriceNormalAPE);
            set_max_mint(maximum_per_mint)
        }

        props.loadingStatus == 1 ?
            setStatus('Loading...') :
        props.isPublicSaleActive ?
            setStatus('Public Sales is ACTIVE!'):
        props.isClaimed & !props.isPublicSaleActive ?
            setStatus('You have claimed your quota!'):
        props.isFreeMint & !props.isPublicSaleActive ?
            setStatus(`You have ${max_mint} free mint quota!`) :
        props.isWhitelist & !props.isPublicSaleActive ?
            setStatus(`You have ${max_mint} whitelist quotas!`) :
            setStatus('Enjoy your ride!')

    });

    const switch_coin_payment = () => {
        setByETH(!byETH);
    }

    return (
        <section id= 'mint' className="tf-section hero-slider">
            <div className="container">
                <div className="row">
                    <div className="col-xl-5 col-md-6 col-12">
                        <div className="block-text pt-24" >
                            <h6 className="sub-title mb-6" data-aos="fade-up" style={{fontSize:"30px"}}>{status}</h6>
                            <h2 className="title mb-26" data-aos="fade-up">GET Your RocKetApe</h2>
                            {/*<p className="title mb-43" data-aos="fade-up">Enjoy your ride!</p>*/}
                        </div>
                    </div>
                    <div className="col-xl-7 col-md-6 col-12">
                        <div className="content-right d-flex">
                            {
                                byETH ?
                                    <div className="mint-box">
                                        <div className="row" style={{paddingBottom:"30px"}}>
                                            <div className="col-12">
                                                <h3>Mint Components</h3>
                                                <h4>Enter the number of RocKet sets you are going to get</h4>
                                            </div>
                                        </div>
                                        <div className="row description-box" >
                                            <div className="col-4">
                                                <img src={props.data.img1} style={{width:"80px"}} alt="image" />
                                            </div>
                                            <div className="col-8" style={{margin:"auto 0"}}>
                                                <h5>4-5 components / set</h5>
                                            </div>
                                        </div>
                                        <div className="row description-box" >
                                            <div className="col-12" >
                                                {
                                                    props.loadingStatus == 1 ?
                                                        <h2 style={{paddingTop:"10px", margin:"auto",textAlign:"center"}}>
                                                            Loading Price...
                                                        </h2> :
                                                        <h2 style={{paddingTop:"10px", margin:"auto",textAlign:"center"}}>
                                                            <a style = {{color:"orangered"}}>{ unit_price_eth }</a> Each Set
                                                        </h2>
                                                }
                                                {/*<h5>{ remaining_set } Remains</h5>*/}
                                            </div>
                                        </div>
                                        <div className="row input-unit-box">
                                            <input
                                                type="number" min="0" max={max_mint}
                                                placeholder={`Maximum ${max_mint} units per minting`}
                                                value={unit}
                                                onChange={(e) => setUnit(parseInt(e.target.value))}
                                                style={{border:0,backgroundColor: "#333333", color: "white"}}
                                            />
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-6" style={{margin:"auto 0",width:"50%"}}>
                                                <h4>Total ETH :</h4>
                                            </div>
                                            <div className="col-sm-6" style={{margin:"auto 0",width:"50%"}}>
                                                <h1>{total_price_eth}</h1>
                                            </div>
                                        </div>
                                        <div>
                                            {
                                                props.loadingStatus == 1 ?
                                                    <h3 style = {{color:"white",textAlign: "center",paddingBottom: "10px"}}>Loading Status...</h3> :

                                                !props.isSaleActive ?
                                                    <button style={{backgroundColor: "transparent",border: 0,marginTop: "30px",marginLeft: "30px",marginRight: "30px",alignItems:"center"}}>
                                                        <a className="btn-disable">
                                                            Sales is not active! <span></span>
                                                        </a>
                                                    </button> :

                                                props.isClaimed & !props.isPublicSaleActive?

                                                    <button style={{backgroundColor: "transparent",border: 0,marginTop: "30px",marginLeft: "30px",marginRight: "30px",alignItems:"center"}}>
                                                        <a className="btn-disable">
                                                            Whitelist claimed! <span></span>
                                                        </a>
                                                    </button> :

                                                !props.isFreeMint & !props.isWhitelist & !props.isPublicSaleActive?

                                                    <button style={{backgroundColor: "transparent",border: 0,marginTop: "30px",marginLeft: "30px",marginRight: "30px",alignItems:"center"}}>
                                                        <a className="btn-disable">
                                                            Not whitelisted! <span></span>
                                                        </a>
                                                    </button> :

                                                unit_is_int &
                                                unit >= 1 &
                                                unit <= max_mint &
                                                total_price_eth <= max_mint * unit_price_eth ?

                                                    <button style={{backgroundColor: "transparent",border: 0,marginTop: "30px",marginLeft: "30px",marginRight: "30px",alignItems:"center"}}>
                                                        <a onClick={() => props.mint_rocketape_eth(unit,total_price_eth,props.merkleProof)} className="btn-action">
                                                            {/*<a onClick={() => props.check_apecoin_equivalent(0.16)} className="default-btn">*/}
                                                            Get your RocKetApe! <span></span>
                                                        </a>
                                                    </button> :
                                                    <button style={{backgroundColor: "transparent",border: 0,marginTop: "30px",marginLeft: "30px",marginRight: "30px",alignItems:"center"}}>
                                                        <a className="btn-disable">
                                                            Wrong Input! <span></span>
                                                        </a>
                                                    </button>
                                            }
                                            {
                                                props.loadingStatus !== 1 ?
                                                    <button style={{backgroundColor: "transparent",border: 0, marginTop: "30px",marginLeft: "30px",marginRight: "30px", alignItems:"center"}}>
                                                        <a className="btn-action" onClick={() => switch_coin_payment()}>
                                                            Mint By &nbsp; <img src={props.data.ape} style={{paddingBottom: "5px",width:"100px",objectFit:"contain"}} alt="Coin"/>
                                                            <span></span>
                                                        </a>
                                                    </button> :
                                                    <></>
                                            }
                                        </div>
                                        {
                                            props.mintStatus === 1 ?
                                                <h3 style = {{color:"white",textAlign: "center",paddingBottom: "10px"}}>Minting...</h3> :
                                            props.mintStatus === 2 ?
                                                <h3 style = {{color:"white",textAlign: "center",paddingBottom: "10px"}}>Mint Successful!</h3> :
                                                <></>
                                        }
                                    </div> :
                                    <div className="mint-box">
                                        <div className="row" style={{paddingBottom:"30px"}}>
                                            <div className="col-12">
                                                <h3>Mint Components</h3>
                                                <h4>Enter the number of RocKet sets you are going to get</h4>
                                            </div>
                                        </div>
                                        <div className="row description-box" >
                                            <div className="col-4">
                                                <img src={props.data.img1} style={{width:"80px"}} alt="image" />
                                            </div>
                                            <div className="col-8" style={{margin:"auto 0"}}>
                                                <h5>4-5 components / set</h5>
                                            </div>
                                        </div>
                                        <div className="row description-box" >
                                            <div className="col-12" >
                                                {
                                                    props.loadingStatus == 1 ?
                                                        <h2 style={{paddingTop:"10px", margin:"auto",width:"80%",textAlign:"center"}}>
                                                            Loading Price...
                                                        </h2> :
                                                        <h2 style={{paddingTop:"10px", margin:"auto",width:"80%",textAlign:"center"}}>
                                                            <a style = {{color:"orangered"}}>{ unit_price_ape }</a> Each Set
                                                        </h2>
                                                }
                                                {/*<h5>{ remaining_set } Remains</h5>*/}
                                            </div>
                                        </div>
                                        <div className="row input-unit-box">
                                            <input
                                                type="number" min="0" max={max_mint}
                                                placeholder={`Maximum ${max_mint} units per minting`}
                                                value={unit}
                                                onChange={(e) => setUnit(parseInt(e.target.value))}
                                                style={{border:0,backgroundColor: "#333333", color: "white"}}
                                            />
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-6" style={{margin:"auto 0",width:"50%"}}>
                                                <h4>Total APE :</h4>
                                            </div>
                                            <div className="col-sm-6" style={{margin:"auto 0",width:"50%"}}>
                                                <h1>{total_price_ape}</h1>
                                            </div>
                                        </div>
                                        <div>
                                            {
                                                props.loadingStatus == 1 ?
                                                    <h3 style = {{color:"white",textAlign: "center",paddingBottom: "10px"}}>Loading Status...</h3> :

                                                !props.isSaleActive ?
                                                    <button style={{backgroundColor: "transparent",border: 0,marginTop: "30px",marginLeft: "30px",marginRight: "30px",alignItems:"center"}}>
                                                        <a className="btn-disable">
                                                            Sales is not active! <span></span>
                                                        </a>
                                                    </button> :

                                                props.isClaimed & !props.isPublicSaleActive?

                                                    <button style={{backgroundColor: "transparent",border: 0,marginTop: "30px",marginLeft: "30px",marginRight: "30px",alignItems:"center"}}>
                                                        <a className="btn-disable">
                                                            Whitelist claimed!<span></span>
                                                        </a>
                                                    </button> :

                                                !props.isFreeMint & !props.isWhitelist & !props.isPublicSaleActive?

                                                    <button style={{backgroundColor: "transparent",border: 0,marginTop: "30px",marginLeft: "30px",marginRight: "30px",alignItems:"center"}}>
                                                        <a className="btn-disable">
                                                            Not whitelisted!<span></span>
                                                        </a>
                                                    </button> :

                                                !props.isAPEEnabled ?
                                                    <button style={{backgroundColor: "transparent",border: 0,marginTop: "30px",marginLeft: "30px",marginRight: "30px",alignItems:"center"}}>
                                                        <a onClick={() => props.approve_apecoin(props.ownerAddress)} className="btn-action" >
                                                            Approve ApeCoin!<span></span>
                                                        </a>
                                                    </button> :

                                                unit_is_int &
                                                unit >= 1 &
                                                unit <= max_mint &
                                                total_price_ape <= max_mint * unit_price_ape ?

                                                    <button style={{backgroundColor: "transparent",border: 0,marginTop: "30px",marginLeft: "30px",marginRight: "30px",alignItems:"center"}}>
                                                        <a onClick={() => props.mint_rocketape_ape(unit,total_price_ape,props.merkleProof)} className="btn-action">
                                                            {/*<a onClick={() => props.check_apecoin_equivalent(0.16)} className="default-btn">*/}
                                                            Get your RocKetApe! <span></span>
                                                        </a>
                                                    </button> :
                                                    <button style={{backgroundColor: "transparent",border: 0,marginTop: "30px",marginLeft: "30px",marginRight: "30px",alignItems:"center"}}>
                                                        <a className="btn-disable">
                                                            Wrong Input! <span></span>
                                                        </a>
                                                    </button>

                                            }
                                            {
                                                props.loadingStatus !== 1 ?
                                                    <button style={{backgroundColor: "transparent",border: 0, marginTop: "30px",marginLeft: "30px",marginRight: "30px", alignItems:"center"}}>
                                                        <a className="btn-action" onClick={() => switch_coin_payment()} >
                                                            Mint By &nbsp; <img src={props.data.eth} style={{paddingBottom: "5px",width:"100px",objectFit:"contain"}} alt="Coin"/>
                                                            <span></span>
                                                        </a>
                                                    </button> :
                                                    <></>
                                            }

                                        </div>
                                        {
                                            props.approveAPEStatus == 1 ?
                                                <h3 style = {{color:"white",textAlign: "center",paddingBottom: "10px"}}>Approving...</h3> :
                                            props.approveAPEStatus == 2 ?
                                                <h3 style = {{color:"white",textAlign: "center",paddingBottom: "10px"}}>Approved!</h3> :
                                                <></>
                                        }
                                        {
                                            props.mintStatus === 1 ?
                                                <h3 style = {{color:"white",textAlign: "center",paddingBottom: "10px"}}>Minting...</h3> :
                                            props.mintStatus === 2 ?
                                                <h3 style = {{color:"white",textAlign: "center",paddingBottom: "10px"}}>Mint Successful!</h3> :
                                                <></>
                                        }
                                    </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );

}

export default Mint;